@use './style-vars.scss' as styleVars;

cds-tag {

    // Workaround because status input is not updated??
    // https://storybook.core.clarity.design/?path=/docs/components-tag--page
    &[status="info"] {
        --border-color: var(--cds-alias-status-info, var(--cds-global-color-blue-700, #0079ad));
        --background: var(--cds-alias-status-tint, var(--cds-alias-status-info-tint, var(--cds-global-color-blue-50, #e6f7ff)));
    }

    &[status="success"] {
        --border-color: var(--cds-alias-status-success, var(--cds-global-color-green-700, #42810e));
        --background: var(--cds-alias-status-tint, var(--cds-alias-status-success-tint, var(--cds-global-color-green-50, #eefce3)));
    }

    &[status="warning"] {
        --border-color: var(--cds-alias-status-warning, var(--cds-global-color-ochre-500, #ffb92e));
        --background: var(--cds-alias-status-tint, var(--cds-alias-status-warning-tint, var(--cds-global-color-ochre-100, #fff2d6)));
    }

    &[status="danger"] {
        --border-color: var(--cds-alias-status-danger, var(--cds-global-color-red-700, #e02200));
        --background: var(--cds-alias-status-tint, var(--cds-alias-status-danger-tint, var(--cds-global-color-red-50, #fff2f0)));
    }

    &:focus {
        outline: none;
    }
}

// add missing display@$breakpoint:grid
// https://github1s.com/vmware-clarity/core/blob/HEAD/projects/core/src/styles/layout/_display.scss
@media (min-width: styleVars.$sm) {
    [cds-layout~="display@sm:grid"] {
        display: grid !important;
    }
}

@media (min-width: styleVars.$md) {
    [cds-layout~="display@md:grid"] {
        display: grid !important;
    }
}

@media (min-width: styleVars.$lg) {
    [cds-layout~="display@lg:grid"] {
        display: grid !important;
    }
}

@media (min-width: styleVars.$xl) {
    [cds-layout~="display@xl:grid"] {
        display: grid !important;
    }
}

// Style links color, visited, hover, active
// https://storybook.core.clarity.design/?path=/docs/foundation-design-tokens--page
a {
    text-decoration: none;
    color: var(--cds-global-typography-link-color, hsl(198, 100%, 34%));

    &:visited,
    &:active,
    &:link {
        color: var(--cds-global-typography-link-color, hsl(198, 100%, 34%));
    }

    &:active,
    &:hover {
        color: var(--cds-global-typography-link-color-hover, hsl(198, 100%, 27%));
    }
}

/** reduce headline font size mobile */
@media screen and (max-width: styleVars.$sm) {
    [cds-text*=display] {
        font-size: var(--cds-global-typography-heading-font-size);
    }
}