/**
https://gist.github.com/Mennez/3f8a1fca40dfd6a3daac86d5cd12ea90

Clarity (http://clarity.design) (at the moment of writing) does not have an auto-complete/combobox component.
ng-select is currently one of the more popular angular2/4 packages for adding auto-complete components to an angular project.
This style sheet allows anyone to use ng-select in a angular+clarity project that uses clarity forms.
To get these styles applied to your ng-select component, declare your form component as follows:
<form clrForm>
  <clr-input-container>
    <label>Foo</label>
    <ng-select clrInput [items]="exampleItems"></ng-select>
  </clr-input-container>
</form>
 */

$ng-select-highlight: var(--clr-color-action-600, var(--fallback-color-no-clr)) !default;
$ng-select-primary-text: rgba(black, 0.87) !default;
$ng-select-primary-light-text: rgba(white, 0.87) !default;
$ng-select-secondary-text: rgba(black, 0.54) !default;
$ng-select-secondary-light-text: rgba(white, 0.54) !default;
$ng-select-disabled-text: rgba(black, 0.38) !default;
$ng-select-divider: rgba(black, 0.12) !default;
$ng-select-bg: #ffffff !default;
$dark-theme-highlight-color: #324f61;

.ng-select {
    font-size: 0.8rem;

    ng-select {
        padding: 0;
        height: auto;
        max-height: none;
    }

    &.ng-select-single {
        .ng-select-container {
            min-height: 28px;
            height: 28px;
        }
    }

    &.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
        border-color: var(--clr-forms-focused-color, hsl(198, 100%, 32%));
        box-shadow: none;

        .ng-arrow {
            border-top: 0.3rem solid var(--clr-forms-focused-color, hsl(198, 100%, 32%));
        }

        color: var(--clr-forms-focused-color, hsl(198, 100%, 32%));
    }

    &:not(.ng-select-opened)>.ng-select-container {
        background-color: inherit;
        border-radius: 0;
        color: #ffffff;
        border: none;
        border-bottom: .05rem solid var(--clr-forms-border-color, #b2b3b3);
        // min-width: 200px;

        .ng-arrow {
            border-left: 0.22rem solid transparent;
            border-right: 0.22rem solid transparent;
            border-top: 0.3rem solid var(--clr-forms-border-color, #b2b3b3);
        }

        &:hover,
        &:active {
            .ng-arrow {
                border-left: 0.22rem solid transparent;
                border-right: 0.22rem solid transparent;
                border-top: 0.3rem solid var(--clr-forms-focused-color, hsl(198, 100%, 32%));
            }

            border-bottom: .05rem solid var(--clr-forms-focused-color, hsl(198, 100%, 32%));
        }


    }


    .clr-input-wrapper {
        max-height: none;

        clr-icon {
            position: absolute;
            right: -8px;
            top: 0;
        }
    }

    .ng-value-container {
        .ng-value {
            border-radius: 10px;
            padding: 0 4px;
            margin: 1px 2px;
            line-height: 22px;
            max-height: 22px;

            span[ng-reflect-ng-item-label^="-"] {
                color: rgba(178, 179, 179, 0.7)
            }
        }

        .ng-value-icon {
            margin-right: 4px;
            margin-left: 8px;

            &:first-of-type {
                margin-left: 0;
            }

            display: inline-block;
        }
    }

    &.ng-select-multiple {
        .ng-select-container {
            .ng-value-container {
                .ng-value {
                    color: $ng-select-primary-light-text;
                    background: $ng-select-highlight;
                    padding: 0 8px;
                }

                .ng-placeholder {
                    top: 12px;
                }
            }
        }

        &.ng-select-opened {
            .ng-select-container {
                .ng-value-container {
                    .ng-value {
                        color: $ng-select-primary-text;
                    }
                }
            }
        }




        .ng-input {
            input {
                padding: 0 0 0 4px;
            }
        }
    }

    .ng-clear-wrapper {
        text-align: center;
        margin-top: 2px;
    }
}

.dark-theme {
    .ng-select {

        input,
        .ng-value,
        .ng-clear-wrapper {
            color: $ng-select-primary-light-text;
        }
    }

    .ng-select-multiple {
        .ng-value-container {
            .ng-value {
                background: $dark-theme-highlight-color;
            }
        }
    }
}

.ng-dropdown-panel {
    background: $ng-select-bg;
    left: 0;

    &.ng-select-bottom {
        top: calc(100%);
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px $ng-select-divider;
    }

    &.ng-select-top {
        bottom: calc(100% - .84375em);
        box-shadow: 0 -5px 5px -3px rgba(0, 0, 0, .2), 0 -8px 10px 1px rgba(0, 0, 0, .14), 0 -3px 14px 2px $ng-select-divider;
    }

    &.multiple {
        .ng-option {
            &.selected {
                background: $ng-select-bg;
            }

            &.marked {
                background: rgba(0, 0, 0, .04);
            }
        }
    }

    .ng-dropdown-header {
        border-bottom: 1px solid $ng-select-divider;
        padding: 0 0.5em;
        line-height: 2.1em;
        min-height: 2.1em;
    }

    .ng-dropdown-footer {
        border-top: 1px solid $ng-select-divider;
        padding: 0 0.5em;
        line-height: 2.1em;
        min-height: 2.1em;
    }

    .ng-dropdown-panel-items {
        .ng-optgroup {
            user-select: none;
            cursor: pointer;
            line-height: 2.1em;
            height: 2.1em;
            padding: 0 0.5em;
            color: $ng-select-secondary-text;
            font-weight: 500;

            &.ng-option-marked {
                background: rgba(0, 0, 0, .04);
            }

            &.ng-option-disabled {
                cursor: default;
            }

            &.ng-option-selected {
                background: $ng-select-divider;
                color: $ng-select-highlight;
            }
        }

        .ng-option {
            line-height: 2.1em;
            min-height: 2.1em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 0.5em;
            text-decoration: none;
            position: relative;
            color: $ng-select-primary-text;
            text-align: left;

            &.ng-option-marked {
                background: rgba(0, 0, 0, .04);
                color: $ng-select-primary-text;
            }

            &.ng-option-selected {
                background: $ng-select-divider;
                color: $ng-select-highlight;
            }

            &.ng-option-disabled {
                color: $ng-select-disabled-text;
            }

            &.ng-option-child {
                padding-left: 1rem;
            }

            .ng-tag-label {
                padding-right: 5px;
                font-size: 80%;
                font-weight: 400;
                color: $ng-select-disabled-text;
            }
        }
    }
}