@use './style-vars.scss' as styleVars;

html,
body {
  position: absolute;
  width: 100%;
  height: 100%;
}


/** These styles cannot be applied in the app.component.css because of style hosting */
.app-container {
  height: 100%;
  width: 100%;
  // height: 100vh;
  overflow: hidden;
  display: block;

  .app-header {
    background: var(--clr-header-bg-color);
    color: var(--clr-header-font-color);
    display: flex;
    justify-content: space-between;
    padding-right: var(--\3b4 5);

    height: var(--header-height);
    line-height: 2.3;

    .responsive-header {
      display: flex;

      height: var(--header-height);
      line-height: 2.3;

      a.title {
        display: none;
      }

      .responsive-burger {
        margin-top: -0.1rem;
        display: none;
      }
    }

    .header-branding {
      display: flex;

      .nav-link {
        color: inherit;
        text-decoration: none;

        &.logo {
          margin-top: -0.2rem;
        }
      }
    }

    .header-nav {
      // background: var(--cds-global-color-blue-900);
      position: relative;
      height: 100%;

      .nav-link {
        height: 100%;
      }

      a {
        color: var(--cds-global-color-gray-0);
        text-decoration: none;

        display: inline-block;

        &:hover {
          // background: var(--cds-global-color-gray-100);
          // color: var(--cds-global-color-blue-1000);
          //text-decoration: underline;
          border-bottom: 10px solid var(--cds-global-color-gray-100);
        }

        &.active {
          // background: var(--cds-global-color-gray-100);
          // color: var(--cds-global-color-blue-1000);
          //text-decoration: underline;
          border-bottom: 10px solid var(--cds-global-color-gray-100);
        }
      }
    }

    cds-icon {
      color: var(--clr-header-font-color);
    }
  }

  @media screen and (max-width: styleVars.$sm) {
    .app-header {
      .responsive-header {
        a.title {
          display: block;
        }
        .responsive-burger {
          display: inline-block;
          cursor: pointer;

          .close {
            display: none;
          }

          /* .burger {
            vertical-align: bottom;
          } */
        }

        &.open {
          position: absolute;
          background: var(--clr-nav-background-color, #e8e8e8);
          color: var(--clr-header-bg-color);
          height: 100%;
          z-index: 2;

          flex-direction: column;
          transition: background-color 0.3s ease;
          box-shadow: 0 calc((1 / 20) * 1rem) calc((10 / 20) * 1rem) 0 hsla(198, 30%, 15%, 0.5);

          cds-icon{
            color: var(--clr-header-bg-color);
          }

          .header-nav {
            height: var(--header-height);
            display: flex;
            flex-direction: column;

            .nav-link {
              color: var(--clr-header-bg-color);

              &.active {
                background: var(--cds-global-color-white);
                border-left: 0.15rem solid var(--cds-alias-status-info-shade);
                border-bottom: unset
              }

              &:hover {
                border-bottom: unset
              }
            }
          }

          .responsive-burger {
            margin-top: 0.4rem;
            .close {
              display: block;
            }

            .burger {
              display: none;
            }
          }
        }

        &:not(.open) {
          .header-nav {
            display: none;
          }
        }

      }

      .header-backdrop {
        display: none;

        &.open {
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgb(20 20 20 / 70%);
          z-index: 1;
        }
      }
    }
  }

  .app-sidenav {
    background: var(--cds-global-color-gray-200);
    -webkit-box-shadow: -0.05rem 0 0 rgb(0 0 0 / 20%) inset;
    box-shadow: -0.05rem 0 0 rgb(0 0 0 / 20%) inset;
    width: 25%;
  }

  .route-container {
    height: calc(100% - var(--header-height));
    width: 100%;

    .app-content {
      height: 100%;
      width: 100%;

      overflow: hidden;
      padding: 0;

      &.scrollable-content {
        overflow-y: auto;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: start;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: start;
        // padding: 0 0.5rem 0 0.5rem;
      }

      // pad footer on bottom of page height not enough
      .pad-footer-bottom {
        min-height: calc(100% - var(--footer-height));
      }
    }
  }

  .footer-wrap {
    height: var(--footer-height);
    position: relative;
  }

  .app-footer {
    height: var(--footer-height);

    position: absolute;
    bottom: 0px;
    width: 100%;
    transition: height 0.4s ease;

    background: var(--clr-header-bg-color);
    color: var(--clr-header-font-color);
    -webkit-box-shadow: 0 0.05rem 0 hsl(0deg 0% 70%) inset;
    box-shadow: 0 0.05rem 0 hsl(0deg 0% 70%) inset;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 0.2rem;


    a {
      color: var(--clr-header-font-color);
      margin-right: 0.5rem;
    }

    .footer-logos {
      display: flex;
      justify-content: right;
      padding-left: 0.5rem;

      /* @media screen and (min-width: styleVars.$xxs) {
        justify-content: left;
      } */

      cds-icon {
        height: calc(var(--footer-height) / var(--gs-ratio));
        width: calc(var(--footer-height) / var(--gs-ratio));
        margin-right: 0.5rem;

        /* @media screen and (min-width: styleVars.$xxs) {
          height: var(--footer-height);
          width: var(--footer-height);
        } */

        &:last-child {
          margin-right: 0rem;
        }
      }
    }

    .footer-links {
      list-style-type: none;
      margin: 0;
      padding: 0 0.5rem 0.2rem 0;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: right;
      font-size: 0.65em;

      /* @media screen and (min-width: styleVars.$xxs) {
        justify-content: flex-end;
      } */

      li {
        padding: 0 0.1rem;
      }

      a {
        text-decoration: none;

        &:hover,
        &:focus,
        &.active {
          text-decoration: underline;
        }
      }
    }


    @media screen and (min-width: styleVars.$xxs) {
      .footer-links {
        font-size: 0.8em;

        li {
          padding: 0 0.3rem;
        }
      }
    }
  }

  /** Set flex column for large footer and small screens*/
  &.large:not(.no-footer-trans) {

    --footer-height: calc(var(--header-height) * var(--gs-ratio));

    @media screen and (max-width: styleVars.$xxs) {
      .app-footer {
        flex-direction: column;
        align-items: center;

        .footer-logos cds-icon {
          height: calc(var(--footer-height)*0.6);
          width: calc(var(--footer-height)*0.6);
        }

        .footer-links {
          font-size: 0.6em;

          li {
            padding: 0 0.2rem;
          }
        }
      }
    }
  }
}