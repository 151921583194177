@use './style-vars' as styleVars;
// @import '@splidejs/splide/css';
@import '@splidejs/splide/css/core';

// ----------------------------

$bottom: auto !default;
$dot-width: 12px !default;
$dot-height: 12px !default;
$dot-background: var(--cds-global-color-construction-100, #e3eaed);
$dot-background-hover: var(--cds-global-color-construction-300, #aeb8bc);
$dot-background-active: var(--cds-global-color-construction-300, #aeb8bc); 
$dot-border: 0 !default;
$dot-border-radius: 50% !default;
$dot-margin: 0.25rem !default;
$dot-padding: 0 !default;
$dot-opacity: .7 !default;
$dot-opacity-hover: .9 !default;
$dot-transition: transform .2s linear !default;
$dot-transform-active: scale(1.4) !default;
$arrow-background: var(--cds-global-color-construction-100, #e3eaed);
$arrow-color: var(--cds-global-color-construction-500, #6a7a81);
$play-background: var(--cds-global-color-construction-100, #e3eaed);
$play-icon-color: var(--cds-global-color-construction-500, #6a7a81);

.splide {
  $root: &;

  &__slide {
    margin-bottom: 2rem;
    width: calc(((100% + 2rem) / 2) - 2rem);

    img {
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: styleVars.$sm) {
      margin-bottom: 1rem;
      width: calc(((100% + 1rem) / 2) - 1rem);
    }
    
    @media screen and (max-width: styleVars.$md) {
      margin-bottom: 2rem;
      width: calc(((100% + 1rem) / 2) - 1rem);
    }
  }

  &__pagination {
    bottom: $bottom;
    left: 0;
    padding: 0 1em;
    right: 0;
    z-index: 1;
    left: auto;
    position: relative;

    &__page {
      background: $dot-background;
      border: $dot-border;
      border-radius: $dot-border-radius;
      display: inline-block;
      height: $dot-height;
      margin: $dot-margin;
      padding: $dot-padding;
      position: relative;
      transition: $dot-transition;
      width: $dot-width;

      @if $dot-opacity {
        opacity: $dot-opacity;
      }

      &.is-active {
        background: $dot-background-active;
        z-index: 1;

        @if $dot-transform-active {
          transform: $dot-transform-active;
        }
      }

      &:hover {
        cursor: pointer;

        @if $dot-opacity-hover {
          opacity: $dot-opacity-hover;
        }

        @if $dot-background-hover {
          background: $dot-background-hover;
        }
      }
    }

  }

  &__arrow {
    background: $arrow-background;
    cursor: pointer;
    align-items: center;
    border: 0;
    border-radius: 50%;
    display: flex;
    height: 2em;
    justify-content: center;
    opacity: .7;
    padding: 0;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    width: 2em;
    z-index: 1;
  
    svg {
      fill: $arrow-color;
      height: 1.2em;
      height: 1.2em;
    }

    &:disabled {
      opacity: .3;
    }

    &:hover:not(:disabled) {
      opacity: .9;
    }

    &--prev {
      left: 1em;

      svg {
        transform: scaleX(-1);
      }
    }

    &--next {
      right: 1em;
    }
  }

  // dots hover
  &__page--custom {
    &:hover {
      background: $dot-background-hover;
    }
  }

  &__controls {
    align-items: center;
    display: flex;
    justify-content: center;
    bottom: auto;
    left: auto;
    position: relative;
    transform: none;
    width: auto;
  }

  // play/pause button
  &__toggle {
    align-items: center;
    background: $play-background;
    border-radius: 50%;
    border-width: 0;
    display: inline-flex;
    height: 1.4rem;
    justify-content: center;
    margin-right: .5rem;
    transition: background-color .2s ease;
    width: 1.4rem;
    cursor: pointer;

    // play icon
    svg {
      fill: $play-icon-color;
      height: 66%;
      transition: fill .2s ease;
      width: 66%;
    }

    &__play {
      margin-left: 3px;
    }

    // play on hover
    &:hover {
      opacity: .9;
    }
  }

  &.is-focus-in {
    #{ $root }__pagination {
      &__page:focus {
        // @include mixins.focus-outline;
      }
    }
  }
}

button:focus {
  outline: none;
  box-shadow: 0 0 0 0 var(--clr-btn-link-color, var(--fallback-color-no-clr));
}