.cds-card {
    --clr-card-clickable-border-color: var(--cds-alias-status-info);
    // box-shadow: var(--clr-card-box-shadow, 0 0.15rem 0 0 var(--cds-alias-object-container-background-shade));
    // border-radius: var(--clr-card-border-radius, 0.15rem);
    border-width: var(--clr-card-border-width, 0.05rem);
    border-style: solid;
    border-color: var(--cds-alias-object-container-border-color);
    overflow: hidden;

    &.no-border {
        border: unset;
        border-radius: unset;
        box-shadow: unset;
    }

    .card-img {
        overflow: hidden;
        img {
            transition: transform .5s ease;
        }
    }

    .cds-card-inner-float {
        display: block;

        width: 100%;
        height: 100%;

        padding-top: var(--cds-global-space-8);
        padding-left: var(--cds-global-space-8);
        padding-right: var(--cds-global-space-8);

        .card-img-and-description {
            min-height: 7rem;
        }

        .card-title {
            cursor: pointer;
            display: block;
            padding-top: 0.1rem;
            text-decoration: none;

            /** from Anchor Scrolling */
            &:focus-visible,
            &.active {
                outline: none;
                color: var(--cds-global-color-blue-700, #0079ad);
                font-weight: bolder;
            }
        }

        .card-text {
            max-height: calc(var(--cds-global-typography-body-line-height, 1.4em) * 5.5);
            overflow: hidden;
            text-overflow: ellipsis;
            // https://designkojo.com/add-ellipse-truncated-text-css-single-line-and-multi-line-options
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            padding-top: var(--cds-global-space-7);
        }

        .card-img {
            padding-top: 0.18rem;
            max-height: 6.8rem;
            margin-right: var(--cds-global-space-7);

            img {
                max-height: 6.8rem;
                width: auto;
            }
        }
    }

    .cds-card-inner {
        display: block;

        width: 100%;
        height: 100%;

        padding-top: var(--cds-global-space-8);
        padding-bottom: var(--cds-global-space-8);
        padding-left: var(--cds-global-space-8);
        padding-right: var(--cds-global-space-8);
    }

    .cds-card-action {
        width: 100%;

        padding-top: var(--cds-global-space-6);
        padding-bottom: var(--cds-global-space-6);
        padding-left: var(--cds-global-space-8);
        padding-right: var(--cds-global-space-8);

        border-top: var(--cds-alias-object-border-width-100, calc(1 * 1rem / var(--cds-global-base, 20))) solid var(--cds-alias-object-container-border-color, var(--cds-global-color-construction-200, #cbd4d8));
    }

    &.clickable {
        --clr-card-clickable-box-shadow-color: var(--cds-alias-status-info);
        --clr-card-clickable-border-color: var(--cds-alias-status-info);
        --clr-card-clickable-box-shadow: 0.1rem 0.1rem 0 0 var(--cds-alias-status-info);

        &:hover {
           /*  box-shadow: var(--clr-card-clickable-box-shadow, 0 0.15rem 0 0 hsl(198, 80%, 46%));
            border-color: var(--clr-card-clickable-border-color, hsl(198, 80%, 46%));
            border-width: var(--clr-card-clickable-border-width, 0.05rem); */
            // border-radius: var(--clr-card-border-radius, 0.15rem);

            img {
                transition: transform .5s ease;
                transform: scale(1.05);
            }
        }
    }
}