.custom-clr-input {
    background-color: inherit;
    border-radius: 0;
    color: #ffffff;
    border: none;
    border-bottom: .05rem solid var(--clr-forms-border-color, #b2b3b3);
    font-size: 0.8rem;
    width: 100%;

    &:hover,
    &:active {
        color: #ffffff;
        border-bottom: .05rem solid var(--clr-forms-focused-color, hsl(198, 100%, 32%));
    }

    &:focus {
        outline: none;
        border-bottom: .05rem solid var(--clr-forms-focused-color, hsl(198, 100%, 32%));
        color: #fff; // var(--clr-forms-focused-color, hsl(198, 100%, 32%));
    }


}

// -----------------------------------------
.custom-clr-checkbox {
    height: 0.8rem;
    width: 0.8rem;
    border: 0.05rem solid;
    border-color: var(--clr-forms-border-color, hsl(198, 10%, 46%));
    border-radius: var(--clr-forms-checkbox-border-radius, 0.15rem);
}

input[type=checkbox]:checked.custom-clr-checkbox {
    accent-color: var(--clr-forms-checkbox-background-color, hsl(198, 100%, 32%));
}