/* You can add global styles to this file, and also import other style files */

@import 'modern-normalize/modern-normalize.css'; // css reset
@import './styles//dlr-frutiger-font.scss';
@import '@cds/core/global.min.css'; // clarity global styles
@import '@cds/city/css/bundles/default.min.css'; // load base font

@import './styles/cds-override.scss';
@import './styles/app-layout.scss';
@import './styles/style-vars.scss';
@import './styles/app-links.scss';
@import './styles/splide-custom.scss';
@import './styles/cds-card.scss';

@import "@ng-select/ng-select/themes/default.theme.css";
@import "./styles/clarity-ng-select.scss";
@import "./styles/clarity-input.scss";
@import "./styles/fullscreen-directive-style.scss";
