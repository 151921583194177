.fullscreen-directive {
    .fullscreen-directive-close {
        display: none;
        position: absolute;
        padding: 0;
        border: none;
        cursor: pointer;

        top: 0rem;
        right: 0rem;
        padding-left: .05rem;
        padding-bottom: .05rem;
        background: var(--clr-header-bg-color);
    }

    .fullscreen-image{
        display: none;
    }

    &.maximized {
        z-index: 1;
        width: 100%;
        height: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        background: #fff;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: flex-end;
        cursor: auto;

        @media (orientation: landscape) {}

        header {
            position: absolute;
            height: calc(var(--header-height) / 2);
            width: 100%;
            background: var(--clr-header-bg-color);
        }

        .fullscreen-directive-close {
            cds-icon {
                color: #fff !important; //var(--clr-header-bg-color);
            }
        }

        .fullscreen-directive-close {
            display: block;
        }

        .preview-image {
            display: none;
        }
        .fullscreen-image{
            display: block;
        }

        img {
            //height: auto !important;
            // width: 100% !important;

            object-fit: contain;
            /* or cover, fill, none, or scale-down */
            width: 100% !important;
            height: calc(100% - calc(var(--header-height) / 2)) !important;
        }

        /* @media (orientation: landscape) {
            img {
                height: calc(100% - calc(var(--header-height) / 2)) !important;
                width: auto !important;
            }
        } */
    }
}