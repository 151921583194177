/**
* requested 
* /assets/media/FrutigerLTW02-55Roman.b1dd7d5f.woff2
* /assets/media/FrutigerLTW02-55Roman.c3e24a79.woff
* /assets/media/FrutigerLTW02-55Roman.fb375ed7.ttf

* /assets/media/FrutigerLTW02-45Light.2caeb5fc.woff2
* /assets/media/FrutigerLTW02-45Light.5ab367c1.woff
* /assets/media/FrutigerLTW02-45Light.8e9779dc.ttf

* /assets/media/FrutigerLTW02-56Italic.66490aad.woff2
* /assets/media/FrutigerLTW02-56Italic.d5b00f26.woff
* /assets/media/FrutigerLTW02-56Italic.f3df479b.ttf

* /assets/media/FrutigerLTW02-65Bold.ed4a917b.woff2
* /assets/media/FrutigerLTW02-65Bold.81e5750d.woff
* /assets/media/FrutigerLTW02-65Bold.bd1472c9.ttf


* requested on dlr homepage
* /assets/media/FrutigerLTW02-55Roman.b1dd7d5f.woff2
* /assets/media/FrutigerLTW02-45Light.2caeb5fc.woff2
* /assets/media/FrutigerLTW02-65Bold.ed4a917b.woff2
*/
/**

baseUrl: 
- /assets/media/
- https://www.dlr.de/static/media/
**/

@font-face {
    font-family: Frutiger LT Pro;
    font-style: normal;
    font-weight: 300;
    src: local("Frutiger LT Pro");
    src: url(/assets/media/FrutigerLTW02-45Light.ae657839.eot?#iefix);
    src: url(/assets/media/FrutigerLTW02-45Light.ae657839.eot?#iefix) format("eot"), url(/assets/media/FrutigerLTW02-45Light.2caeb5fc.woff2) format("woff2"), url(/assets/media/FrutigerLTW02-45Light.5ab367c1.woff) format("woff"), url(/assets/media/FrutigerLTW02-45Light.8e9779dc.ttf) format("truetype")
}

@font-face {
    font-family: Frutiger LT Pro;
    font-style: italic;
    font-weight: 300;
    src: local("Frutiger LT Pro");
    src: url(/assets/media/FrutigerLTW02-46LightItalic.9f6ba29b.eot?#iefix);
    src: url(/assets/media/FrutigerLTW02-46LightItalic.9f6ba29b.eot?#iefix) format("eot"), url(/assets/media/FrutigerLTW02-46LightItalic.633dfff4.woff2) format("woff2"), url(/assets/media/FrutigerLTW02-46LightItalic.34d294f7.woff) format("woff"), url(/assets/media/FrutigerLTW02-46LightItalic.776791ed.ttf) format("truetype")
}

@font-face {
    font-family: Frutiger LT Pro;
    font-style: normal;
    font-weight: 400;
    src: local("Frutiger LT Pro");
    src: url(/assets/media/FrutigerLTW02-55Roman.b720e3f2.eot?#iefix);
    src: url(/assets/media/FrutigerLTW02-55Roman.b720e3f2.eot?#iefix) format("eot"), url(/assets/media/FrutigerLTW02-55Roman.b1dd7d5f.woff2) format("woff2"), url(/assets/media/FrutigerLTW02-55Roman.c3e24a79.woff) format("woff"), url(/assets/media/FrutigerLTW02-55Roman.fb375ed7.ttf) format("truetype")
}

@font-face {
    font-family: Frutiger LT Pro;
    font-style: italic;
    font-weight: 400;
    src: local("Frutiger LT Pro");
    src: url(/assets/media/FrutigerLTW02-56Italic.6bc99dcc.eot?#iefix);
    src: url(/assets/media/FrutigerLTW02-56Italic.6bc99dcc.eot?#iefix) format("eot"), url(/assets/media/FrutigerLTW02-56Italic.66490aad.woff2) format("woff2"), url(/assets/media/FrutigerLTW02-56Italic.d5b00f26.woff) format("woff"), url(/assets/media/FrutigerLTW02-56Italic.f3df479b.ttf) format("truetype")
}

@font-face {
    font-family: Frutiger LT Pro;
    font-style: normal;
    font-weight: 700;
    src: local("Frutiger LT Pro");
    src: url(/assets/media/FrutigerLTW02-65Bold.ffb36373.eot?#iefix);
    src: url(/assets/media/FrutigerLTW02-65Bold.ffb36373.eot?#iefix) format("eot"), url(/assets/media/FrutigerLTW02-65Bold.ed4a917b.woff2) format("woff2"), url(/assets/media/FrutigerLTW02-65Bold.81e5750d.woff) format("woff"), url(/assets/media/FrutigerLTW02-65Bold.bd1472c9.ttf) format("truetype")
}

@font-face {
    font-family: Frutiger LT Pro;
    font-style: italic;
    font-weight: 700;
    src: local("Frutiger LT Pro");
    src: url(/assets/media/FrutigerLTW02-66BoldItalic.a7a2104b.eot?#iefix);
    src: url(/assets/media/FrutigerLTW02-66BoldItalic.a7a2104b.eot?#iefix) format("eot"), url(/assets/media/FrutigerLTW02-66BoldItalic.86f5606f.woff2) format("woff2"), url(/assets/media/FrutigerLTW02-66BoldItalic.cb878ba8.woff) format("woff"), url(/assets/media/FrutigerLTW02-66BoldItalic.ba2fd074.ttf) format("truetype")
}

@font-face {
    font-family: Frutiger LT Pro;
    font-style: normal;
    font-weight: 800;
    src: local("Frutiger LT Pro");
    src: url(/assets/media/FrutigerLTW02-75Black.621a4c14.eot?#iefix);
    src: url(/assets/media/FrutigerLTW02-75Black.621a4c14.eot?#iefix) format("eot"), url(/assets/media/FrutigerLTW02-75Black.f4e70bc8.woff2) format("woff2"), url(/assets/media/FrutigerLTW02-75Black.7ef20844.woff) format("woff"), url(/assets/media/FrutigerLTW02-75Black.cbe17179.ttf) format("truetype")
}

@font-face {
    font-family: Frutiger LT Pro;
    font-style: italic;
    font-weight: 800;
    src: local("Frutiger LT Pro");
    src: url(/assets/media/FrutigerLTW02-76BlackItalic.5ae1c49d.eot?#iefix);
    src: url(/assets/media/FrutigerLTW02-76BlackItalic.5ae1c49d.eot?#iefix) format("eot"), url(/assets/media/FrutigerLTW02-76BlackItalic.7bcaadaf.woff2) format("woff2"), url(/assets/media/FrutigerLTW02-76BlackItalic.9c1b2b7a.woff) format("woff"), url(/assets/media/FrutigerLTW02-76BlackItalic.6669ef20.ttf) format("truetype")
}

@font-face {
    font-family: Frutiger LT Pro;
    font-style: normal;
    font-weight: 900;
    src: local("Frutiger LT Pro");
    src: url(/assets/media/FrutigerLTW02-95UltraBlack.8f4c3829.eot?#iefix);
    src: url(/assets/media/FrutigerLTW02-95UltraBlack.8f4c3829.eot?#iefix) format("eot"), url(/assets/media/FrutigerLTW02-95UltraBlack.f77cf7b9.woff2) format("woff2"), url(/assets/media/FrutigerLTW02-95UltraBlack.c82ef429.woff) format("woff"), url(/assets/media/FrutigerLTW02-95UltraBlack.4b6757ad.ttf) format("truetype")
}

@font-face {
    font-family: Frutiger LT Cond Pro;
    font-style: normal;
    font-weight: 300;
    src: local("Frutiger LT Cond Pro");
    src: url(/assets/media/FrutigerLTW02-47LightCond.70c92ede.eot?#iefix);
    src: url(/assets/media/FrutigerLTW02-47LightCond.70c92ede.eot?#iefix) format("eot"), url(/assets/media/FrutigerLTW02-47LightCond.0ffc6b83.woff2) format("woff2"), url(/assets/media/FrutigerLTW02-47LightCond.1e98d731.woff) format("woff"), url(/assets/media/FrutigerLTW02-47LightCond.77899bfc.ttf) format("truetype")
}

@font-face {
    font-family: Frutiger LT Cond Pro;
    font-style: italic;
    font-weight: 300;
    src: local("Frutiger LT Cond Pro");
    src: url(/assets/media/FrutigerLTW02-48LightCondIt.696902bb.eot?#iefix);
    src: url(/assets/media/FrutigerLTW02-48LightCondIt.696902bb.eot?#iefix) format("eot"), url(/assets/media/FrutigerLTW02-48LightCondIt.b81375a0.woff2) format("woff2"), url(/assets/media/FrutigerLTW02-48LightCondIt.1bf9119e.woff) format("woff"), url(/assets/media/FrutigerLTW02-48LightCondIt.0d918a8b.ttf) format("truetype")
}

@font-face {
    font-family: Frutiger LT Cond Pro;
    font-style: normal;
    font-weight: 400;
    src: local("Frutiger LT Cond Pro");
    src: url(/assets/media/FrutigerLTW02-57Condensed.0f71cd31.eot?#iefix);
    src: url(/assets/media/FrutigerLTW02-57Condensed.0f71cd31.eot?#iefix) format("eot"), url(/assets/media/FrutigerLTW02-57Condensed.636df247.woff2) format("woff2"), url(/assets/media/FrutigerLTW02-57Condensed.8eca1b59.woff) format("woff"), url(/assets/media/FrutigerLTW02-57Condensed.f5086b28.ttf) format("truetype")
}

@font-face {
    font-family: Frutiger LT Cond Pro;
    font-style: italic;
    font-weight: 400;
    src: local("Frutiger LT Cond Pro");
    src: url(/assets/media/FrutigerLTW02-58CondensedIt.1e17c91e.eot?#iefix);
    src: url(/assets/media/FrutigerLTW02-58CondensedIt.1e17c91e.eot?#iefix) format("eot"), url(/assets/media/FrutigerLTW02-58CondensedIt.fae9df2b.woff2) format("woff2"), url(/assets/media/FrutigerLTW02-58CondensedIt.badc2d85.woff) format("woff"), url(/assets/media/FrutigerLTW02-58CondensedIt.540b88d8.ttf) format("truetype")
}

@font-face {
    font-family: Frutiger LT Cond Pro;
    font-style: normal;
    font-weight: 700;
    src: local("Frutiger LT Cond Pro");
    src: url(/assets/media/FrutigerLTW02-67BoldCond.e9e119d3.eot?#iefix);
    src: url(/assets/media/FrutigerLTW02-67BoldCond.e9e119d3.eot?#iefix) format("eot"), url(/assets/media/FrutigerLTW02-67BoldCond.8c7c5ae6.woff2) format("woff2"), url(/assets/media/FrutigerLTW02-67BoldCond.3cf15b4b.woff) format("woff"), url(/assets/media/FrutigerLTW02-67BoldCond.f56a24f2.ttf) format("truetype")
}

@font-face {
    font-family: Frutiger LT Cond Pro;
    font-style: italic;
    font-weight: 700;
    src: local("Frutiger LT Cond Pro");
    src: url(/assets/media/FrutigerLTW02-68BoldCondIt.a2be7022.eot?#iefix);
    src: url(/assets/media/FrutigerLTW02-68BoldCondIt.a2be7022.eot?#iefix) format("eot"), url(/assets/media/FrutigerLTW02-68BoldCondIt.1d7ca338.woff2) format("woff2"), url(/assets/media/FrutigerLTW02-68BoldCondIt.36b3f7ab.woff) format("woff"), url(/assets/media/FrutigerLTW02-68BoldCondIt.4f499e0c.ttf) format("truetype")
}

@font-face {
    font-family: Frutiger LT Cond Pro;
    font-style: normal;
    font-weight: 800;
    src: local("Frutiger LT Cond Pro");
    src: url(/assets/media/FrutigerLTW02-77BlackCond.bccc4f20.eot?#iefix);
    src: url(/assets/media/FrutigerLTW02-77BlackCond.bccc4f20.eot?#iefix) format("eot"), url(/assets/media/FrutigerLTW02-77BlackCond.af5b423a.woff2) format("woff2"), url(/assets/media/FrutigerLTW02-77BlackCond.9678dabd.woff) format("woff"), url(/assets/media/FrutigerLTW02-77BlackCond.158ef96d.ttf) format("truetype")
}

@font-face {
    font-family: Frutiger LT Cond Pro;
    font-style: italic;
    font-weight: 800;
    src: local("Frutiger LT Cond Pro");
    src: url(/assets/media/FrutigerLTW02-78BlackCondIt.fd70bace.eot?#iefix);
    src: url(/assets/media/FrutigerLTW02-78BlackCondIt.fd70bace.eot?#iefix) format("eot"), url(/assets/media/FrutigerLTW02-78BlackCondIt.fdde8d39.woff2) format("woff2"), url(/assets/media/FrutigerLTW02-78BlackCondIt.6d4c107d.woff) format("woff"), url(/assets/media/FrutigerLTW02-78BlackCondIt.368fe9c2.ttf) format("truetype")
}

@font-face {
    font-family: Frutiger LT Cond Pro;
    font-style: normal;
    font-weight: 800;
    src: local("Frutiger LT Cond Pro");
    src: url(/assets/media/FrutigerLTW02-87XBlackCond.7e452e8e.eot?#iefix);
    src: url(/assets/media/FrutigerLTW02-87XBlackCond.7e452e8e.eot?#iefix) format("eot"), url(/assets/media/FrutigerLTW02-87XBlackCond.181870a0.woff2) format("woff2"), url(/assets/media/FrutigerLTW02-87XBlackCond.3fe723a4.woff) format("woff"), url(/assets/media/FrutigerLTW02-87XBlackCond.12aef248.ttf) format("truetype")
}

@font-face {
    font-family: Frutiger LT Cond Pro;
    font-style: italic;
    font-weight: 800;
    src: local("Frutiger LT Cond Pro");
    src: url(/assets/media/FrutigerLTW02-88XBlackCnIt.47e51bb4.eot?#iefix);
    src: url(/assets/media/FrutigerLTW02-88XBlackCnIt.47e51bb4.eot?#iefix) format("eot"), url(/assets/media/FrutigerLTW02-88XBlackCnIt.343c0c37.woff2) format("woff2"), url(/assets/media/FrutigerLTW02-88XBlackCnIt.6726febb.woff) format("woff"), url(/assets/media/FrutigerLTW02-88XBlackCnIt.a717f952.ttf) format("truetype")
}