// change link color, visited, hover, active
a.disabled {
    pointer-events:none;
}
/*a {
    text-decoration: none;
    color: var(--cds-global-typography-link-color);

    &:active {
        color: var(--cds-global-typography-link-color);
    }

    &:hover {
        color: var(--cds-global-typography-link-color-hover);
    }

    &:visited {
        color: var(--cds-global-typography-link-color);
    }

    &:visited:hover {
        color: var(--cds-global-typography-link-color-hover);
    }
}*/