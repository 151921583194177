:root {
  --gs-ratio: 1.6180339887; // Goldener Schnitt
  --header-height: 80px; // 3rem; // check height fits with Padding p:md p@md:md
  --footer-height: calc(var(--header-height) * var(--gs-ratio));
  --cds-global-typography-top-gap-height: 0.1475em;
  --cds-global-typography-section-line-height: 1.2em;
  --cds-global-typography-link-color: var(--cds-global-color-blue-600);
  --cds-global-typography-link-color-hover: var(--cds-global-color-blue-500);
  --clr-header-bg-color: #00658b; // DLR CD // #333; // #012d44;
  --clr-header-font-color: #fafafa;
  --cds-alias-status-info: #00658b; // DLR CD https://intranet.dlr.de/Seiten/4dc693af-21b0-4781-a8f9-f51d1f5a4fb3/Inhalt/Richtlinien%20zur%20visuellen%20Gestaltung%20(CD-Handbuch).aspx?itemid=d21e9728-ce84-41b0-8eae-c922107e6b11&containerid=d755d16b-0e19-4ea3-9c18-3a3809b386cf&termId=4dc693af-21b0-4781-a8f9-f51d1f5a4fb3&vTerms=ContentByTopic
  --cds-global-typography-font-family: "Frutiger LT Pro", sans-serif, "Clarity City", "Avenir Next", sans-serif;
  --cds-global-typography-header-font-family: "Frutiger LT Pro", sans-serif, "Clarity City", "Avenir Next", sans-serif;
  --app-bg-color: #ebebeb; // DLR CD
  --cds-alias-object-border-radius-100: none;
}

// https://www.mediaevent.de/css/breakpoints.html
// https://storybook.core.clarity.design/?path=/story/layout-utilities--page
// https://storybook.core.clarity.design/?path=/story/layout-get-started--page#Responsive

$xxxl: 1920px;
$xxl: 1600px;
$xl: 1440px; // @xl
$lg: 1200px; // @lg
$mdl: 1024px;
$md: 992px; // @md
$sm: 768px; // @sm
$xs: 576px; // @xs

$xxs: 480px;
$xxxs: 375px;

// https://worship.agency/mobile-screen-sizes-for-2021
$x4s: 360px;
$x5s: 320px;